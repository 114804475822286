import React, { useEffect, useState } from 'react'
import { WideCard } from '../components/wide-card'
import data from '../data'
import { useParams } from 'react-router-dom'
import Checkbox from '../components/checkbox'

type functionalArea = {
	name: string
	title: string
	tagline: string
	blurb?: string[]
	class?: string
	image?: string
}

type useCase = {
	title: string
	blurb?: string
	class?: string
	image?: string
	benefits?: string[]
	functionalAreas?: string[]
}

interface ParamTypes {
	filter: string
}
export const UseCases = (): React.ReactElement => {
	const [activeFilter, setActiveFilter] = useState<string>('All')
	const [subFilters, setSubFilters] = useState<string[]>([])
	const [activeArea, setActiveArea] = useState<functionalArea>()
	const { filter } = useParams<ParamTypes>()

	const cardSortFunction = (a: useCase, b: useCase) => {
		const nameA = a.title.toUpperCase() // ignore upper and lowercase
		const nameB = b.title.toUpperCase() // ignore upper and lowercase
		if (nameA < nameB) {
			return -1
		}
		if (nameA > nameB) {
			return 1
		}

		// names must be equal
		return 0
	}

	useEffect(() => {
		if (filter && filter !== '') {
			setActiveFilter(filter)
			if (filter === 'All') setSubFilters(data.functionalAreas.map((functionalArea) => functionalArea.name))
		}
	}, [filter])

	useEffect(() => {
		const foundArea = data.functionalAreas.find((area) => area.name === activeFilter)
		if (foundArea) setActiveArea(foundArea)
		else setActiveArea(undefined)
	}, [activeFilter])

	const updateSubFilter = (area: string) => {
		if (subFilters.includes(area)) {
			const newSubFilters = [...subFilters]
			const index = newSubFilters.indexOf(area)
			if (index > -1) {
				newSubFilters.splice(index, 1)
				setSubFilters(newSubFilters)
			}
		} else {
			const newSubFilters = [...subFilters]
			newSubFilters.push(area)
			setSubFilters(newSubFilters)
		}
	}

	return (
		<>
			<section className={`head ${activeArea?.class}`} id="head">
				<div className="container-body">
					<div className="container-title">
						<div>
							<h1>{activeArea?.title}</h1>
						</div>
						<div className="subt">
							<span>{activeArea?.tagline}</span>
						</div>
					</div>
				</div>
			</section>

			<section className="section-white">
				<div className="container-body">
					{activeFilter === 'All' ? (
						<div className="rack-quad">
							{data.functionalAreas
								.filter((functArea) => functArea.name !== 'All')
								.map((area) => (
									<Checkbox
										name={area.name}
										key={'chk' + area.name}
										title={area.name}
										checked={subFilters.includes(area.name)}
										onChange={updateSubFilter}
									/>
								))}
						</div>
					) : (
						<div className="apis-intro">
							<div className="apis-txt">
								<p>
									{activeArea?.blurb?.map((line: string, idx) => (
										<React.Fragment key={idx}>
											{line}
											<br />
											<br />
										</React.Fragment>
									))}
								</p>
							</div>
							{activeArea?.image && (
								<div className="apis-img">
									<img className="apis-img-size" src={activeArea.image} alt={activeArea.name} />
								</div>
							)}
						</div>
					)}
					<h2 className="smallh2">Use Cases</h2>
					<div className="rack-list">
						{data.useCases
							.filter(
								(useCase) =>
									(activeFilter === 'All' &&
										useCase.functionalAreas.some((funcArea) => subFilters.includes(funcArea))) ||
									useCase.functionalAreas.includes(activeFilter)
							)
							.sort(cardSortFunction)
							.map((useCase) => (
								<WideCard useCase={useCase} key={useCase.title} />
							))}
					</div>
				</div>
			</section>
		</>
	)
}
export default UseCases
