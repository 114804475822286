import React from 'react'
interface useCase {
	title: string
	image: string
	blurb: string
	benefits: string[]
}

interface wideCardProps {
	useCase: useCase
}
export const WideCard = ({ useCase }: wideCardProps): React.ReactElement => {
	return (
		<div className="card-list">
			<div className="img-card-list">
				<img className="img-size-list" src={useCase.image} alt={useCase.title} />
			</div>
			<div className="txt-list">
				<h3>{useCase.title}</h3>
				<span>
					{useCase.blurb}
					<br />
					<h4>Benefits</h4>
					<ul>
						{useCase.benefits.map((benefit, idx) => (
							<li key={idx}>{benefit}</li>
						))}
					</ul>
				</span>
			</div>
			{/*<a href="#" className="readmore">
				READ MORE
			</a>*/}
		</div>
	)
}
