import React from 'react'
import pic1 from '../assets/home-page-rule-your-data.png'
import pic2 from '../assets/home-page-keep-eveyone-in-check.png'
import pic3 from '../assets/home-page-boost-your-marketing.png'

import cognizant from '../assets/logo_Cognizant_white.png'
import intel from '../assets/logo_Intel_white.png'
import mckinsey from '../assets/logo_McKinsey-Company_white.png'
import nestle from '../assets/logo_Nestle_white.png'
import officemax from '../assets/logo_Office-Depot-OfficeMax_white.png'
import paypal from '../assets/logo_PayPal-full_white.png'
import seimens from '../assets/logo_Siemens_white.png'

import fast from '../assets/fast.svg'
import agile from '../assets/agile.svg'
import flexible from '../assets/flexible.svg'
import { Link } from 'react-router-dom'
import Slider from '../components/slider'
import Slide from '../components/slide'

export const Home = (): React.ReactElement => {
	return (
		<>
			<section className="home" id="home">
				<div className="container-body">
					<div className="container-title">
						<div>
							<h1>Integrate business decisioning data into everything your company does.</h1>
						</div>
						<div className="subt">
							<span>
								Be it growing revenue, increasing margins, mitigating risk or maintaining compliance,
								D&amp;B Direct APIs allow you easily and conveniently leverage D&amp;B data in your
								solutions.
							</span>
						</div>
						<div className="div-btn-home">
							<Link to="/register" className="btn-register-b">
								Register
							</Link>
						</div>
					</div>
				</div>
			</section>

			<section className="section-white" id="cases">
				<div className="container-body">
					<div className="rack-triple">
						<div className="card-triple">
							<div className="img-card">
								<img className="img-size-triple" src={pic1} alt="pic1" />
							</div>
							<div className="card-title">
								<h3>Rule Your Data</h3>
							</div>
							<div className="txt-triple">
								<span>
									Clean and organize your database to create one version of the truth with the D-U-N-S
									Number.
								</span>
							</div>
							{/*<div className="readmore">
								<span>READ MORE</span>
							</div>*/}
						</div>
						<div className="card-triple">
							<div className="img-card">
								<img className="img-size-triple" src={pic2} alt="pic2" />
							</div>
							<div className="card-title">
								<h3>Keep Everyone In-Check</h3>
							</div>
							<div className="txt-triple">
								<span>
									Help your company verify that it doesn&apos;t work with &quot;bad actors&quot;,
									whether you&apos;re buying or selling.
								</span>
							</div>
							{/*<div className="readmore">
								<span>READ MORE</span>
							</div>*/}
						</div>
						<div className="card-triple">
							<div className="img-card">
								<img className="img-size-triple" src={pic3} alt="pic3" />
							</div>
							<div className="card-title">
								<h3>Boost Your Marketing</h3>
							</div>
							<div className="txt-triple">
								<span>
									Capture intelligence on exactly what businesses have visited your website for
									personalization and segmenting.
								</span>
							</div>
							{/*<a href="#" className="readmore">
								READ MORE
							</a>*/}
						</div>
					</div>
					<div className="btn-center">
						<Link to="/use-cases/All" className="btn-register-b">
							Explore all use cases
						</Link>
					</div>
				</div>
			</section>

			<section className="section-blue" id="logos">
				<div className="imgRow">
					  <div className="imgColumn">
						&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<img src={cognizant} alt="Cognizant"  className="logoWidth" />
					  </div>
					  <div className="imgColumn">
						<img src={intel} alt="Intel"  className="logoWidth" />
					  </div>
					  <div className="imgColumn">
						<img src={mckinsey} alt="McKinsey"  className="logoWidth" />
					  </div>
					  <div className="imgColumn">
						<img src={nestle} alt="Nestle"  className="logoWidth" />
					  </div>
					  <div className="imgColumn">
						<img src={officemax} alt="OfficeDepot-OfficeMax"  className="logoWidth" />
					  </div>
					  <div className="imgColumn">
						<img src={paypal} alt="Paypal"  className="logoWidth" />
					  </div>
					  <div className="imgColumn">
						<img src={seimens} alt="Seimens"  className="logoWidth" />
					  </div>
				</div>
			</section>			

			<section className="section-white" id="#">
				<div className="container-body">
					<h2>Build your future</h2>
					<p className="space">
						Whether you&apos;re just entering the workforce or you&apos;re an experienced developer, D&amp;B
						APIs can enable you to fundamentally impact your B2B business: from helping ensure your business
						doesn&apos;t work with bad-actors, to helping find the clients that will buy your product.
					</p>
					<div className="rack-triple">
						<div className="card-icon-triple">
							<div className="icon-card">
								<img className="icon-size-triple" src={flexible} alt="flexible" />
							</div>
							<div className="card-title">
								<h3>Flexible</h3>
							</div>
							<div className="txt-triple">
								<span>
									While our database continues to grow, our APIs allow you to pick and choose exactly
									what data you want to access.
								</span>
							</div>
						</div>
						<div className="card-icon-triple">
							<div className="icon-card">
								<img className="icon-size-triple" src={fast} alt="fast" />
							</div>
							<div className="card-title">
								<h3>Fast</h3>
							</div>
							<div className="txt-triple">
								<span>
									Sometimes the cloud needs to touch the ground: we&apos;ve ensured that the
									connections it makes give everyone high performance.
								</span>
							</div>
						</div>
						<div className="card-icon-triple">
							<div className="icon-card">
								<img className="icon-size-triple" src={agile} alt="agile" />
							</div>
							<div className="card-title">
								<h3>Agile</h3>
							</div>
							<div className="txt-triple">
								<span>
									Build where you want: be it in your CRM, supply management system, web application,
									mobile device or somewhere else.
								</span>
							</div>
						</div>
					</div>
				</div>
			</section>

			<section className="section-navy-blue">
				<div className="container-body">
					<div className="rack-double">
						<div className="card-double">
							<div className="txt-double">
								<span>
									Get the most from Dun &amp; Bradstreet Direct APIs with a fundamental understanding
									of D&amp;B data.
								</span>
							</div>
							<div className="btn-center">
								<a
									href="https://www.dnb.com/about-us/data-cloud.html"
									target="_blank"
									rel="noreferrer external"
									className="btn-register-b"
									title="learn more"
								>
									Learn More
								</a>
							</div>
						</div>
						<div className="card-double">
							<div className="txt-double">
								<span>
									Register to become a member of our program: once confirmed, you can start building
									the solutions that will change your company.
								</span>
							</div>
							<div className="btn-center">
								<Link to="/register" className="btn-register-b">
									Register
								</Link>
							</div>
						</div>
					</div>
				</div>
			</section>
		</>
	)
}
export default Home
