import handshake from './assets/handshake.svg'
import creditRisk from './assets/credit-risk.svg'
import collaborate from './assets/collaborate.svg'
import cycle from './assets/cycle.svg'
import connected from './assets/connected.svg'
import supplier from './assets/supplier.svg'
import law from './assets/law.svg'
import increase from './assets/increase.svg'
import caution from './assets/caution.svg'
import realTime from './assets/real-time.svg'
import discover from './assets/discover.svg'
import target from './assets/target.svg'
import digital from './assets/digital.svg'
import optimization from './assets/optimization.svg'
import segment from './assets/segment.svg'
import location from './assets/location.svg'
import influencer from './assets/influencer.svg'
import streamline from './assets/streamline.svg'
import my from './assets/my.svg'
import useCaseFinance from './assets/use-case-finance.gif'
import useCaseMarketing from './assets/use-case-marketing.gif'
import useOperationsRisk from './assets/use-case-operations-risk.gif'
import useSalesMasterData from './assets/use-case-sales-master-data.gif'

export const data = {
	functionalAreas: [
		{
			name: 'All',
			title: 'An API for any occasion.',
			tagline:
				'Our APIs are built with use cases in mind and help you improve efficiencies and cut costs by optimizing workflows, providing instant insight and driving return on investment.',
			blurb: [],
			class: 'bg-all',
			image: undefined
		},
		{
			name: 'Finance',
			title: 'APIs to help your finance teams',
			tagline:
				"From helping ensure everyone gets paid to helping reduce the company's risk of working with bad actors, your finance team needs to optimize process and integrate multiple data sets.",
			blurb: [
				'According to research conducted by Dun & Bradstreet, about 40% of finance professionals want to optimize their resources and integrate multiple systems despite the tools they have at their disposal today. D&B Direct APIs can help do both: able to integrate with any API friendly system, you can help your finance team facilitate compliance processes, identify risk, reduce bad dept and synchronize the flow of information across systems.',
				'In doing so, you can become the person who bridged the gap, increased collaborating and improved experience for third-party vendors, business partners, and company employees.'
			],
			class: 'bg-finance',
			image: useCaseFinance
		},
		{
			name: 'Marketing',
			title: 'APIs to help your marketing teams',
			tagline:
				"We're missing information on our leads. We don’t know enough about who visits our website. SalesForce or Oracle isn't playing nice with Marketo or Pardot. Sales isn't following up with our leads: they say they are bad. Does this sound familiar? D&B Direct APIs can help.",
			blurb: [
				"It's inevitable that marketing – advertising, segmenting, better digital experiences, etc. – rely more and more on technology and data. And while this might feel like a complicating factor for your marketing teams, it's really an opportunity for you to shine because you know APIs love that very data and technology.",
				"Be it capturing more information about business visitors to your website, understanding social footprints, capturing more information on forms or segmenting your database of clients, D&B Direct APIs can help. When these connections are made, you'll be the hero who figured how improve the sales funnel while reducing workload."
			],
			class: 'bg-marketing',
			image: useCaseMarketing
		},
		{
			name: 'Sales',
			title: 'APIs to help your sales teams',
			tagline:
				"From making your company money to developing critical relationships, your sales team needs help, they may just not realize that's through technology and data.",
			blurb: [
				'Sales people are of a certain creed: conscientious, goal-oriented, personable, able to remember a lot of details about who they work with. However, technology has created an expectation of hyper personalization: just think about how many things remember your name, your preferences, your auto-fill information and even your password (Okay, maybe you have this turned-off but you get the idea). ',
				"Your sales teams now need to know and remember so much more: let's help them out. D&B Direct APIs can with figuring out who a buyer is at a company (and who will influence them), what territories the sales team should go to -  and avoid, and keep tabs on an accounts and what they've been up to. Don't you want to be a sales hero?"
			],
			class: 'bg-sales',
			image: useSalesMasterData
		},
		{
			name: 'Operations',
			title: 'APIs to help your operations teams',
			tagline:
				"Operations has the elusive role of ensuring everything gets done from cost optimization to regularity compliance. While it may not seem like the fun stuff, it's the life blood that keeps your company moving, and it could run smoother. Just think of how many times you wanted to work with a vendor and had to get them approved first.",
			blurb: [
				"Operations, due to its name, would appear to be a group that runs smoothly. However, given the number of things this group keeps running, there's often a major opportunity to improve – whether they know it or not. D&B Direct APIs can help your operations team: identify low-cost yet stable sources of global vendors, monitor supply and delivery performance with in your SCM application, avoid regulatory fines and penalties due to non-compliance, and streamline the order management process. ",
				"When you help improve these not-so-sexy business processes, you can be the hero that improved relationships between company employees and your company's relationship with third-party vendors."
			],
			class: 'bg-operations',
			image: useOperationsRisk
		}
	],
	useCases: [
		{
			title: 'Business Due Diligence',
			blurb: 'Onboard new accounts and negotiate the best new deals for your business, globally.',
			image: handshake,
			benefits: [
				'Review company prospects financials, ratings, ownership structure, compliance, and more to help identify potential financial, legal or regulatory risks',
				'Streamline and shorten the entire due diligence process'
			],
			functionalAreas: ['Finance']
		},
		{
			title: 'Credit Portfolio Management',
			blurb:
				'Identify opportunities to help reduce risk and increase revenue directly within your ERP or accounting applications.',
			image: creditRisk,
			benefits: [
				'Better manage scoring and rules for credit limits and collections prioritization',
				'Automating monitoring at the portfolio level',
				'Improve quality and speed of decision making '
			],
			functionalAreas: ['Finance']
		},
		{
			title: 'Collections Prioritization',
			blurb: 'Help reduce bad debt by Identifying the customers likely – and unlikely – to pay.',
			image: collaborate,
			benefits: [
				'Deliver invoices to the correct person, department, and location',
				'Increase cash flow, speed, and effectiveness of collections'
			],
			functionalAreas: ['Finance']
		},
		{
			title: 'Portfolio Monitoring',
			blurb:
				'Maintain more accurate information across all of your systems to help reduce costs and increase  financial efficiencies ',
			image: cycle,
			benefits: [
				'Enrich your customer records with real-time updates with D&B Direct Monitoring ',
				'Optimize the process for creating and managing risk scores and rules'
			],
			functionalAreas: ['Finance']
		},
		{
			title: 'Source Cost Optimization',
			blurb:
				'Improve cost efficiencies in your supply base by identifying low-cost yet stable sources of global vendors for procurement.',
			image: connected,
			benefits: [
				'Evaluate new and current suppliers on their financials, ratings, compliance and beneficial ownership',
				'Help avoid risky vendors and better negotiate contracts with chosen vendors'
			],
			functionalAreas: ['Operations']
		},
		{
			title: 'Supplier and Distributor Performance',
			blurb: 'Monitor supply and delivery performance with in your SCM application.',
			image: supplier,
			benefits: [
				'Quickly identify corporate relationships among suppliers and distributors',
				'Save time and more effectively measure performance over time'
			],
			functionalAreas: ['Operations']
		},
		{
			title: 'Regulatory Compliance Management',
			blurb:
				'Help avoid regulatory fines and penalties due to non-compliance with streamlined monitoring and audit management across your firm.',
			image: law,
			benefits: [
				'Document and manage the audit trail to help demonstrate firm & 3rd party compliance',
				'Reduce the time, effort, and cost of your compliance program'
			],
			functionalAreas: ['Operations']
		},
		{
			title: 'Order Management Optimization',
			blurb: 'Streamline and improve the order management process.',
			image: increase,
			benefits: [
				'Help eliminate inaccuracies and incomplete customer data at the point of entry',
				'Reduce fulfillment errors with accurate shipping information',
				'Improve cash flow'
			],
			functionalAreas: ['Operations']
		},
		{
			title: 'Supply Chain Risk Mitigation',
			blurb:
				'Uncover and alleviate risks across the supply chain in order to help avoid supply disruptions, optimize your distribution network, and increase profitable growth',
			image: caution,
			benefits: [
				'Predict potential supply chain disruptions and identity alternative suppliers',
				"Enrich your supplier records with D&B's company information ",
				'Help maintain compliance to avoid penalties'
			],
			functionalAreas: ['Operations']
		},
		{
			title: 'Master Data Management',
			blurb: 'Clean and organize your database in real-time to create one version of the truth.',
			image: realTime,
			benefits: [
				'Optimize data management with real-time matching, enrichment and delivery of data',
				'Gain a single view of your accounts and decrease time spent maintaining records'
			],
			functionalAreas: ['Operations', 'Finance', 'Marketing', 'Sales']
		},
		{
			title: 'Web Visitor Identification',
			blurb:
				'Web Visitor ID identifies what businesses come to your website, helping you move your visitor from prospect to valued customer.',
			image: discover,
			benefits: [
				'Customize the digital experience of your web visitors with real-time personalized content',
				'With instant access to company information of your most important visitors, you can focus on targeting your marketing dollars'
			],
			functionalAreas: ['Marketing']
		},
		{
			title: 'Marketing Optimization',
			blurb:
				'Optimize targeting, positioning, and nurturing of prospects and customers with more accurate profiles.',
			image: target,
			benefits: [
				'Create an improved buyer experience to attract, grow, and retain customers',
				'Improve the productivity of your marketing and sales team'
			],
			functionalAreas: ['Marketing']
		},
		{
			title: 'Social and Digital Intelligence',
			blurb: "Leverage buyers' online footprints and behavior to enhance marketing and sales efforts.",
			image: digital,
			benefits: [
				'Help improve lead scoring with propensity to buy models and deep understanding of unmet needs',
				'Shorten the sales cycle'
			],
			functionalAreas: ['Marketing', 'Sales']
		},
		{
			title: 'Lead Form Optimization',
			blurb:
				'Enrich lead submissions with a complete buyer profile to help you build more accurate records, identify the right prospects and increase conversion rates.',
			image: optimization,
			benefits: [
				'Keep your leads fresh with our real-time push monitoring updates',
				'Shorten forms to just 4 fields to increase conversion and gather company profile information from D&B on the back end to segment and target'
			],
			functionalAreas: ['Marketing']
		},
		{
			title: 'Segmentation and Prospect Identification',
			blurb: 'Find the best new prospects and opportunities in current accounts directly in your CRM.',
			image: segment,
			benefits: [
				'Accurately model your best customers to find more like them for territory planning and marketing strategy',
				'Help increase revenue while creating marketing and sales team efficiencies'
			],
			functionalAreas: ['Marketing', 'Sales']
		},
		{
			title: 'Territory Management',
			blurb:
				'Develop a better understanding of revenue sources and market opportunities to optimize sales resource allocation directly in your CRM.',
			image: location,
			benefits: [
				'Gain and maintain a more accurate view of accounts buying centers and corporate relationships',
				'Reduce cost of sales and channel conflict while uncovering new growth opportunities with territory insights and predictive analytics '
			],
			functionalAreas: ['Sales']
		},
		{
			title: 'Buyer and Influencer Mapping',
			blurb: 'Accelerate and help close deals with intelligence on key decision-makers.',
			image: influencer,
			benefits: [
				'Pinpoint the right decision makers and influencers with a more accurate view of account, key contacts, and buying centers',
				'Increase sales effectiveness and productivity '
			],
			functionalAreas: ['Sales']
		},
		{
			title: 'Buyer Activity Notifications',
			blurb:
				'Better service and grow accounts by streamlining information sharing across service, sales and marketing systems.',
			image: streamline,
			benefits: [
				'Gain and maintain a 360 view of company and contact information',
				'Increase customer retention and grow accounts'
			],
			functionalAreas: ['Sales']
		},
		{
			title: 'Sales Rep Customer Knowledge',
			blurb:
				'Get all the insights sales needs – in one place – to better understand customers and build strong relationships.',
			image: my,
			benefits: [
				'Efficiently prepare for sales calls with account monitoring based on social, news and buyer activity notifications',
				'Enable sales teams to be true business advisors who can recommend the right solutions'
			],
			functionalAreas: ['Sales']
		}
	],
	logos: [{}]
}

export default data
