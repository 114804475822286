import React from 'react'

export interface CheckboxProps {
	name: string
	title: string
	checked: boolean
	onChange: (name: string) => void
}

export const Checkbox = ({ name, title, checked, onChange }: CheckboxProps): React.ReactElement => {
	return (
		<div className="subfilter">
			<input
				type="checkbox"
				id={name}
				key={'chk' + name + checked}
				name={name}
				value={name}
				defaultChecked={checked}
				onChange={() => onChange(name)}
			/>
			<label htmlFor={name}>{title}</label>
		</div>
	)
}
export default Checkbox
