import React, { useEffect } from 'react'

declare global {
	interface Window {
		// The structure of the aptrinsic plugin in is external to us and we don't know the type
		// so disabling next line no-explicit-any
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		MktoForms2: any
	}
}
window.MktoForms2 = window.MktoForms2 || undefined

export interface ExternalRegistrationFormProps {
	formId: number
}

export const ExternalRegistrationForm = ({ formId }: ExternalRegistrationFormProps): React.ReactElement => {
	useEffect(() => {
		const MktoForms2 = window.MktoForms2
		// Depending on your lint, you may need this
		// eslint-disable-next-line no-undef
		if (MktoForms2) MktoForms2.loadForm('//info01.dnb.com', '080-UQJ-704', formId)
	}, [formId])

	return (
		<>
			<form id={`mktoForm_${formId}`}></form>
		</>
	)
}
