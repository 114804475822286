import React from 'react'
import { HashRouter, Redirect, Route, Switch } from 'react-router-dom'
import './App.css'
import { Home } from './pages/home'
import { NotFound } from './pages/not-found'
import { Header } from './components/header'
import UseCases from './pages/use-cases'
import ScrollToTop from './scroll-to-top'
import { Register } from './pages/register'
import { Footer } from './components/footer'

function App() {
	return (
		<>
			<HashRouter>
				<Header />
				<main>
					<Switch>
						<Route path="/home" component={Home} />
						<Route path="/use-cases/:filter" component={UseCases} />
						<Route path="/register" component={Register} />
						<Route exact path="/">
							<Redirect to="/home" />
						</Route>
						<Route exact path="/index.html">
							<Redirect to="/home" />
						</Route>
						<Route path="*">
							<NotFound />
						</Route>
					</Switch>
				</main>
				<Footer />
				<ScrollToTop />
			</HashRouter>
		</>
	)
}

export default App
