import React, { PropsWithChildren, useEffect, useState } from 'react'
import './modal.scss'

export interface ModalProps {
	open?: boolean
	onClose(event: React.MouseEvent<HTMLDivElement>): void
}

export const Modal: React.FC<PropsWithChildren<ModalProps>> = ({
	children,
	open = false,
	onClose
}: PropsWithChildren<ModalProps>) => {
	const [noScrollSet, setNoScrollSet] = useState(false)
	const noScrollClass = 'no-scroll-on-body'
	useEffect(() => {
		if (open) {
			if (!document.body.classList.contains(noScrollClass)) {
				document.body.classList.add(noScrollClass)
				setNoScrollSet(true)
			}
		} else {
			if (noScrollSet) {
				document.body.classList.remove(noScrollClass)
				setNoScrollSet(false)
			}
		}
		return () => {
			if (noScrollSet) {
				document.body.classList.remove(noScrollClass)
				setNoScrollSet(false)
			}
		}
	}, [open, noScrollSet])

	return (
		<>
			{open && (
				<div className={`modal-overlay ${open && 'open'}`}>
					<div className="modal type-notification">
						<div
							className="button-close"
							onClick={(e) => {
								if (onClose) onClose(e)
							}}
						>
							<div id="mdiv">
								<div className="mdiv">
									<div className="md"></div>
								</div>
							</div>
						</div>
						{children}
					</div>
				</div>
			)}
		</>
	)
}

export default Modal
